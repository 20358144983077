<template>
  <button class="btnToggle" :class="color">{{ btnTxt }}</button>
</template>

<script>
export default {
  name: "btnToggle",
  props: {
    btnTxt: String,
    color: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.btnToggle {
  width: 76px;
  height: 20px;
  @extend .f_caption;
  white-space: nowrap;
}
.primary {
  color: variables.$primary;
  border: solid 1px variables.$primary;
  box-sizing: border-box;
  opacity: 0.3;
  &.on {
    background: variables.$primary;
    color: #fff;
    opacity: 1;
  }
}
.neutral {
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #cbcbcb;
  color: variables.$txt-default;
}
.negative {
  color: variables.$negative;
  border: solid 1px variables.$negative;
  box-sizing: border-box;
  opacity: 0.3;
  &.on {
    background: variables.$negative;
    color: #fff;
    opacity: 1;
  }
}
.positive1 {
  background: #063d7c;
  color: #fff;
}
.positive2 {
  background: #5c86b5;
  color: #fff;
}
.back {
  background: variables.$bg-gray-3;
  color: #fff;
}
</style>
