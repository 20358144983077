<template>
  <div>
    <Header />
    <pageTitle txt="追加発注 詳細" :backBtn="backBtn" />
    <div id="meta">
      <span class="shopName">{{ $route.query.shopName }}</span>
      <span class="shopTel">TEL : {{ $route.query.tel }}</span>
      <div class="meta-btn">
        <btnS class="print" btnTxt="変更を確定" color="primary" @click="save" />
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <ul class="product">
            <li class="date"></li>
            <li class="vacuum"></li>
            <li
              class="product-item"
              v-for="product in orderList[0].products"
              :key="product.item_id"
            >
              {{ product.name }}
            </li>
          </ul>
        </li>
        <orderDetailItem
          v-for="(item, key) in orderList"
          :key="item.id"
          class="weekday"
          :item="item"
          :products="item.products"
          :year="item.year"
          :month="item.month"
          :date="item.day"
          :day="key"
          :orderNum="key"
          :dayName="item.weekName"
          :time="item.thisDayTime"
          :extra="true"
          edit="edit"
          @changeList="resetOrderList"
          :class="{
            saturday: item.weekName == '土',
            holiday: item.weekName == '日' || item.is_holiday == '1'
          }"
        />
      </ul>
    </div>
    <!-- <div id="fixedMenu">
      <router-link
        :to="{
          path: '/iseya/order/extraDetail/',
          query: {
            shopID: $route.query.shopID,
            shopName: $route.query.shopName,
            tel: $route.query.tel,
            date: $route.query.date
          }
        }"
      >
        <btnS btnTxt="戻る" color="back" />
      </router-link>
    </div> -->
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
#print {
  width: 945px;
  height: 668px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
  .list {
    transform: scale(0.69);
    transform-origin: top left;
  }
}

#print-meta {
  margin-bottom: 20px;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}
#meta {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #fcfcfc;
  box-sizing: border-box;
  padding: 0 30px 0 15px;
}

.meta-btn {
  margin-left: auto;
}

.shopName {
  font-size: 1.5rem;
  font-weight: 500; /* medium */
  letter-spacing: 0.06em;
  margin-right: 30px;
}

.shopTel {
  font-size: 1.5rem;
  font-weight: 500; /* medium */
  letter-spacing: 0.06em;
}

.status {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.status-btn {
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  box-sizing: border-box;
  padding: 0 10px;

  border: solid 1px variables.$txt-default;
  height: 28px;
  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.edit {
  margin-left: 30px;
}

.print {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 90px;
}

.list {
  box-sizing: border-box;
  border-left: solid 1px variables.$bg-gray-2;
  //border-right: solid 1px variables.$bg-gray-2;
  @extend .f_body;
  //margin-bottom: 20px;
  display: flex;
}

.list-head {
  width: 160px;
  min-width: 160px;
}

.date {
  height: 100px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.vacuum {
  height: 30px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
}

.product-item {
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
  height: 100px;
  display: flex;
  align-items: center;
  &:nth-of-type(odd) {
    background: variables.$bg-gray-1;
  }
}
</style>

<style lang="scss" scoped>
@media print {
  /*
  @page {
    size: landscape;
    margin: 0;
  }
  */

  #wrapper,
  #fixedMenu {
    display: none !important;
  }
  #print {
    width: 251mm;
    height: 172mm;
    page-break-after: always;

    .list {
      transform: scale(1);
    }
  }
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import orderDetailItem from "@/components/iseya/orderDetailItem";
import btnS from "@/components/btn/btnS";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    orderDetailItem,
    btnS
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      today: "",
      orderList: []
    };
  },
  async created() {
    const param = {
      store_id: this.$route.query.shopID,
      start_date: this.$route.query.date,
      other_id: 0
    };
    const api = new ApiClient();
    const result = await api.post("/store/order/list", param);
    this.orderList = result.list;

    this.$store.commit("setOrderData", this.orderList);

    this.today = new Date();
    this.start_date = new Date(this.$route.query.date);
    this.end_date = new Date(this.$route.query.date);
    this.end_date.setDate(this.end_date.getDate() + 6);
  },
  methods: {
    async save() {
      const param = {
        store_id: this.$route.query.shopID,
        list: this.orderList
      };
      const api = new ApiClient();
      await api.post(`/store/order/save`, param);

      const query =
        "?shopID=" +
        this.$route.query.shopID +
        "&shopName=" +
        this.$route.query.shopName +
        "&date=" +
        this.$route.query.date +
        "&tel=" +
        this.$route.query.tel;
      this.$router.push("/iseya/order/extraDetail/" + query);
    },
    resetOrderList() {
      this.$store.commit("setOrderData", this.orderList);
    }
  }
};
</script>
